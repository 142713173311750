.data-management {
	& .dt-bootstrap5 {
		& .table {
			margin-top: 0 !important;
			margin-bottom: 0 !important;
			color: var(--#{$prefix}body-color);
		}
		& .dt-search {
			text-align: left;
			margin-bottom: $spacer * .75;
			display: flex;
			
			& label {
				display: flex;
				align-items: center;
			}
			& input {
				border-radius: $border-radius;
				font-size: $font-size-base;
				min-width: rem(200px);
				
				@include media-breakpoint-down(lg) {
					min-width: inherit;
					flex: 1;
					width: 100%;
				}
			}
		}
	}
}