.table {
	&.table-card {
		& thead,
		& tbody {
			& > tr {
				& td,
				& th {
					&:first-child {
						@if $enable-rtl {
							padding-right: $card-spacer-x;
						} @else {
							padding-left: $card-spacer-x;
						}
					}
					&:last-child {
						@if $enable-rtl {
							padding-left: $card-spacer-x;
						} @else {
							padding-right: $card-spacer-x;
						}
					}
				}
			}
		}
	}
	&.table-xs {
		font-size: $font-size-sm;
		
		& thead,
		& tbody,
		& tfoot {
			& th {
				padding: $table-cell-padding-y * .75 $table-cell-padding-x;
			}
			& td {
				padding: $table-cell-padding-y * .75 $table-cell-padding-x;
			}
		}
	}
}