.app {
	@include media-breakpoint-up(lg) {
		&.app-boxed-layout {
			background: var(--#{$prefix}body-bg);
			min-height: 100%;
			
			&,
			& .app-header {
				max-width: rem(1280px);
				margin-left: auto;
				margin-right: auto;
			}
			& .app-top-nav {
				max-width: rem(1280px);
				margin-left: auto;
				margin-right: auto;
				overflow: hidden;
			
				& .menu .menu-item .menu-submenu {
					position: fixed;
					left: auto;
					right: auto;
					top: auto;
				}
			}
			& .app-sidebar {
				background: var(--#{$prefix}body-bg);
				
				@if $enable-rtl {
					right: auto;
				} @else {
					left: auto;
				}
			}
			& .app-content {
				background: var(--#{$prefix}body-bg);
			}
		}
	}
}
.app-with-bg {
	@include media-breakpoint-up(lg) {
		background-image: url('../images/bg.jpg');
		background-size: cover;
		background-position: center;
		background-attachment: fixed;
		background-repeat: no-repeat;
	}
}