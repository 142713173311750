pre {
	display: block;
	padding: $spacer;
	font-size: $font-size-base;
	color: rgba(var(--#{$prefix}body-color-rgb), .75);
	word-break: break-all;
	word-wrap: break-word;
	background-color: $white;
	border: 1px solid var(--#{$prefix}border-color);
	border-radius: $border-radius;
}