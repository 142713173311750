.hljs {
  display: block;
  overflow-x: auto;
  color: $gray-300;
}
.hljs-code,
.hljs-emphasis {
  font-style: italic;
}
.hljs-tag {
  color: $gray-700;
}
.hljs-name {
	color: $gray-600;
}
.hljs-variable,
.hljs-template-variable,
.hljs-selector-id,
.hljs-selector-class {
  color: #ade5fc;
}
.hljs-string,
.hljs-bullet {
  color: mix($gray-900, $yellow, 5%);
}
.hljs-attr {
	color: mix($gray-900, mix($white, $info, 25%), 5%);
}
.hljs-type,
.hljs-title,
.hljs-section,
.hljs-attribute,
.hljs-quote,
.hljs-built_in,
.hljs-builtin-name {
  color: #ffa;
}
.hljs-number,
.hljs-symbol,
.hljs-bullet {
  color: #d36363;
}
.hljs-keyword,
.hljs-selector-tag,
.hljs-literal {
  color: #fcc28c;
}
.hljs-comment,
.hljs-deletion,
.hljs-code {
  color: #888;
}
.hljs-regexp,
.hljs-link {
  color: #c6b4f0;
}
.hljs-meta {
  color: #fc9b9b;
}
.hljs-deletion {
  background-color: #fc9b9b;
  color: #333;
}
.hljs-addition {
  background-color: #a2fca2;
  color: #333;
}
.hljs a {
  color: inherit;
}
.hljs a:focus,
.hljs a:hover {
  color: inherit;
  text-decoration: underline;
}
.hljs-container {
	background: $gray-900;
	position: relative;
  padding: ($card-spacer-y * 2) $card-spacer-x;
  
  .dark-mode & {
  	background: shade-color($gray-900, 30%);
  }
	
	& pre {
		border: none;
		padding: 0;
		margin: 0;
		background: none;
		border-radius: 0;
	}
}