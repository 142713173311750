.btn-default {
	@include button-variant($white, $gray-300, $gray-800, $gray-100, $gray-300, $gray-800);
}
.btn-outline-default {
	@include button-outline-variant($gray-300, $gray-900);
	color: $gray-900;
}
@if $enable-dark-mode {
  @include color-mode(dark, true) {
		.btn-default {
			@include button-variant($gray-800, $gray-800, $white, $gray-700, $gray-700, $white);
		}
		.btn-outline-default {
			@include button-outline-variant($gray-600, $gray-300);
			color: $gray-300;
		}
  }
}


.btn-theme {
	--#{$prefix}btn-color: var(--#{$prefix}theme-color);
	--#{$prefix}btn-bg: var(--#{$prefix}theme);
	--#{$prefix}btn-border-color: var(--#{$prefix}theme);
	--#{$prefix}btn-hover-color: var(--#{$prefix}theme-color);
	--#{$prefix}btn-hover-bg: var(--#{$prefix}theme);
	--#{$prefix}btn-hover-border-color: transparent;
	--#{$prefix}btn-focus-shadow-rgb: var(--#{$prefix}theme-rgb);
	--#{$prefix}btn-active-color: var(--#{$prefix}theme-color);
	--#{$prefix}btn-active-bg: var(--#{$prefix}theme);
	--#{$prefix}btn-active-border-color: transparent;
	--#{$prefix}btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--#{$prefix}btn-disabled-color: rgba(var(--#{$prefix}theme-color-rgb), .5);
	--#{$prefix}btn-disabled-bg: rgba(var(--#{$prefix}theme-rgb), .5);
	--#{$prefix}btn-disabled-border-color: transparent;
	
	position: relative;
	
	&:before {
		content: '';
		position: absolute;
		top: -1px;
		left: -1px;
		right: -1px;
		bottom: -1px;
		border-radius: var(--#{$prefix}btn-border-radius);
		
		@include transition($btn-transition);
	}
	&:hover,
	&:focus,
	&:active,
	&.active {
		&:before {
			background: rgba(var(--#{$prefix}theme-color-rgb), .2);
		}
	}
}
.btn-outline-theme {
	color: var(--#{$prefix}theme);
	border-color: var(--#{$prefix}theme);
	
	&:hover,
	&.active {
		background-color: var(--#{$prefix}theme);
		border-color: var(--#{$prefix}theme);
	}
	&.disabled,
	&:disabled {
		color: var(--#{$prefix}theme);
	}
	&:focus,
	&:active,
	&.active:focus, 
	&:active:focus {
		@include box-shadow(0 0 0 0.25rem rgba(#{var(--#{$prefix}theme-rgb)}, .5));
	}
}

