.table_actions{
	color:"black";
	text-decoration: "none";
	cursor:"pointer";
	display:'flex';
	gap:5;
	align-items:'center';
}

@font-face {
    font-family: 'k24_light';
    src: url('./assets/K24KurdishLight-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'k24_bold';
    src: url('./assets/K24KurdishBold-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}