.jvm-container {
	& .jvm-zoomin, 
	& .jvm-zoomout, 
	& .jvm-goback {
		width: rem(20px);
		height: rem(20px);
		padding: 0 0 rem(2px);
		box-sizing: border-box;
		font-size: rem(16px);
		background: var(--#{$prefix}component-bg);
		color: var(--#{$prefix}body-color);
		border: none;
		left: $card-spacer-x;
		box-shadow: 0 .5rem 1rem rgba(var(--#{$prefix}black-rgb),.15);
		border-radius: var(--#{$prefix}border-radius);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
		
		@if $enable-rtl {
			right: $card-spacer-x;
			left: auto;
		} @else {
			left: $card-spacer-x;
		}
		
		&:hover {
			color: var(--#{$prefix}theme);
			background: var(--#{$prefix}component-bg);
		}
	}
	& .jvm-zoomin {
		top: $card-spacer-x;
	}
	& .jvm-zoomout {
		top: $card-spacer-x + rem(25px);
	}
	&.jvectormap-without-padding {
		& .jvm-zoomin {
			top: 0;
		}
		& .jvm-zoomout {
			top: rem(25px);
		}
		& .jvm-zoomin,
		& .jvm-zoomout {
			@if $enable-rtl {
				right: 0;
				left: auto;
			} @else {
				left: 0;
			}
		}
	}
	& .jvm-legend {
		background: var(--#{$prefix}body-bg);
		color: var(--#{$prefix}body-color);
		box-shadow: $dropdown-box-shadow;
		border-radius: 0;
	}
	& .jvm-legend-title {
		font-size: $font-size-base - rem(3px);
		padding: $spacer * .3 $spacer * .5;
		background: rgba(var(--#{$prefix}body-color-rgb), .15);
	}
	& .jvm-legend-cnt-v {
		& .jvm-legend {
			padding: 0;
			margin: $card-spacer-x $card-spacer-x 0 0;
			
			& .jvm-legend-inner {
				padding: $spacer * .5;
			}
			& .jvm-legend-tick {
				display: flex;
				align-items: center;
			}
			& .jvm-legend-tick-sample {
				width: rem(16px);
				height: rem(16px);
			}
			& .jvm-legend-tick-text {
				padding-left: $spacer * .5;
			}
		}
	}
}
.jvm-tooltip {
	background: rgba(var(--#{$prefix}body-color-rgb), .9) !important;
	color: var(--#{$prefix}body-bg) !important;
	font-weight: $font-weight-bold !important;
	padding: rem(4px) rem(8px) !important;
	border: none !important;
	font-family: inherit !important;
	font-size: $font-size-base * .9 !important;
	z-index: 1000;
	border-radius: $border-radius !important;
}