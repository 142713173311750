.new_order{
    .order_container{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }
    .row {
        margin-bottom: 15px; /* Adds space between rows */
        row-gap: 10px;
    }
    .form_buttons{
        padding-top: 25px;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
    }
}