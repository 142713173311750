.general_style{
    position: relative;
    .text_icon_center{
        align-items: center;
        display: flex;
        gap:0.5rem;
    }
    .table_action_buttons{
        color:"black";
        text-decoration: "none";
        cursor:"pointer";
        display:'flex';
        gap:5;
        align-items:'center';
    }
    .loading_overlay{
        width: 100%;
        height: 100%;
        background-color: #ebeef4 ;
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.8;
    }
    .costText{
    font-weight:700;        
    }
}

