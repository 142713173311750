body .daterangepicker {
	--#{$prefix}daterangepicker-bg: #{$dropdown-bg};
	z-index: 1020;
	border: none;
	font-family: inherit;
	font-size: inherit;
	border-radius: $border-radius-lg;
	box-shadow: $box-shadow-lg;
	background-color: var(--#{$prefix}daterangepicker-bg);
	min-width: rem(300px);
	
	&:before,
	&:after {
		display: none;
	}
	& select.monthselect,
	& select.yearselect {
		font-size: rem(13px);
	}
	& .drp-calendar {
		max-width: inherit;
		padding: $spacer;
		background: none;
		
		& .calendar-table {
			background: none;
			border: none;
			
			& table {
				& thead,
				& tbody {
					& .week {
						font-size: 90%;
						color: $gray-600;
						font-weight: $font-weight-bold;
					}
				}
				& thead {
					& tr {
						& th {
							min-width: auto;
							width: auto;
							height: auto;
							line-height: 1;
							padding: rem(7px) rem(7px);
							color: var(--#{$prefix}body-color);
							font-weight: bold;
							font-size: inherit;
							border: none;
							
							&.prev,
							&.next {
								& span {
									border-color: var(--#{$prefix}body-color);
								}
								&:hover,
								&:focus {
									background: var(--#{$prefix}light);
									color: var(--#{$prefix}body-color);
								}
							}
							&.month {
								& .yearselect,
								& .monthselect {
									border-color: $dropdown-border-color;
									color: var(--#{$prefix}body-color);
									background: var(--#{$prefix}component-bg);
									border-radius: $border-radius;
									transition: all .2s linear;
									
									&:focus {
										outline: none;
										border-color: $input-focus-border-color;
										box-shadow: 0 0 0 0.125rem rgba($input-focus-border-color ,.3);
									}
								}
							}
						}
						&:last-child {
							& th {
								padding-bottom: rem(14px);
							}
						}
					}
				}
				& tbody {
					& tr {
						& td {
							min-width: auto;
							width: auto;
							height: auto;
							line-height: 1;
							padding: rem(9px) rem(9px);
							font-weight: 400;
							color: var(--#{$prefix}body-color);
							border: none;
							font-size: inherit;
							
							&.available {
								border-radius: $border-radius;
								
								&:hover,
								&:focus {
									background: var(--#{$prefix}light);
								}
								&.in-range {
									background: $dropdown-link-hover-bg;
									color: $dropdown-link-hover-color;
									border-radius: 0;
								}
								&.active {
									background: var(--#{$prefix}theme);
									color: var(--#{$prefix}theme-color);
								}
								&.start-date {
									border-radius: $border-radius 0 0 $border-radius;
								}
								&.end-date {
									border-radius: 0 $border-radius $border-radius 0;
								}
							}
							&.off {
								background: none;
								color: rgba(var(--#{$prefix}body-color-rgb), .25);
							}
						}
					}
				}
			}
		}
		&.left {
			padding: rem(10px) !important;
			
			& .calendar-table {
				padding: 0 !important;
			}
		}
		&.right {
			padding: rem(10px) !important;
			
			@if $enable-rtl {
				border-right: 1px solid $dropdown-border-color;
			} @else {
				border-left: 1px solid $dropdown-border-color;
			}
			
			@include media-breakpoint-down(sm) {
				border-top: 1px solid $dropdown-border-color;
				
				@if $enable-rtl {
					border-right: none;
				} @else {
					border-left: none;
				}
			}
		}
	}
	& .drp-buttons {
		padding: rem(12px);
		border-color: $dropdown-border-color;
		
		& .btn {
			font-size: rem(13px);
			font-weight: $font-weight-semibold;
			
			&.btn-primary {
				--#{$prefix}btn-color: var(--#{$prefix}theme-color);
				--#{$prefix}btn-bg: var(--#{$prefix}theme);
				--#{$prefix}btn-border-color: var(--#{$prefix}theme);
				--#{$prefix}btn-hover-color: var(--#{$prefix}theme-color);
				--#{$prefix}btn-hover-bg: var(--#{$prefix}theme);
				--#{$prefix}btn-hover-border-color: transparent;
				--#{$prefix}btn-focus-shadow-rgb: var(--#{$prefix}theme-rgb);
				--#{$prefix}btn-active-color: var(--#{$prefix}theme-color);
				--#{$prefix}btn-active-bg: var(--#{$prefix}theme);
				--#{$prefix}btn-active-border-color: transparent;
				--#{$prefix}btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
				--#{$prefix}btn-disabled-color: rgba(var(--#{$prefix}theme-color-rgb), .5);
				--#{$prefix}btn-disabled-bg: rgba(var(--#{$prefix}theme-rgb), .5);
				--#{$prefix}btn-disabled-border-color: transparent;
	
				position: relative;
	
				&:before {
					content: '';
					position: absolute;
					top: -1px;
					left: -1px;
					right: -1px;
					bottom: -1px;
					border-radius: var(--#{$prefix}btn-border-radius);
		
					@include transition($btn-transition);
				}
				&:hover,
				&:focus,
				&:active,
				&.active {
					&:before {
						background: rgba(var(--#{$prefix}theme-color-rgb), .2);
					}
				}
			}
		}
		& .drp-selected {
			font-weight: $font-weight-semibold;
			color: lighten($dark, 30%);
		}
	}
	& .ranges {
		display: none;
	}
	&.show-ranges {
		& .ranges {
			position: relative;
			display: block;
			
			@include media-breakpoint-down(md) {
				float: none;
				margin: 0;
				padding: rem(10px);
				border-bottom: 1px solid $dropdown-border-color;
				overflow: scroll;
				max-width: rem(560px);
			}
			
			& ul {
				@include media-breakpoint-down(md) {
					width: auto;
					white-space: nowrap;
					display: flex;
					flex-wrap: nowrap;
					
					@if $enable-rtl {
						padding-left: rem(10px);
					} @else {
						padding-right: rem(10px);
					}
				}
				
				& li {
					color: rgba(var(--#{$prefix}body-color-rgb), .5);
					font-size: $font-size-base - rem(1px);
					font-weight: 400;
					
					@include media-breakpoint-down(md) {
						border-radius: $border-radius;
						
						@if $enable-rtl {
							margin-left: rem(10px) !important;
						} @else {
							margin-right: rem(10px) !important;
						}
					}
					
					&:hover,
					&:focus {
						background: $dropdown-link-hover-bg;
						color: $dropdown-link-hover-color;
					}
					&.active {
						background: var(--#{$prefix}theme);
						color: var(--#{$prefix}theme-color);
						
						@if $enable-rtl {
							margin-left: rem(-1px);
						} @else {
							margin-right: rem(-1px);
						}
					}
				}
			}
		}
		& .drp-calendar {
			&.left { 
				@if $enable-rtl {
					border-right: 1px solid $dropdown-border-color !important;
				} @else {
					border-left: 1px solid $dropdown-border-color !important;
				}
				
				@include media-breakpoint-down(md) {
					@if $enable-rtl {
						border-right: none !important;
					} @else {
						border-left: none !important;
					}
				}
			}
		}
	}
}

@if $enable-dark-mode {
  @include color-mode(dark, true) {
  	body .daterangepicker {
  		--#{$prefix}daterangepicker-bg: #{$dropdown-bg-dark};
  	}
  }
}