.error-page {
	min-height: 100%;
	padding: rem(60px) 0;
	display: flex;
	align-items: center;
	
	& .error-page-content {
		margin: 0 auto;
		position: relative;
		padding: 0 rem(15px);
		text-align: center;
		
		& .error-img {
			position: relative;
			width: rem(360px);
			margin: rem(15px) auto;
			
			@include media-breakpoint-down(sm) {
				width: rem(320px);
			}
			
			& .error-img-code {
				position: absolute;
				color: $gray-800;
				left: 0;
				right: 0;
				font-size: rem(116px);
				text-align: center;
				line-height: 1.25;
				
				@if $enable-rtl {
					padding-left: rem(35px);
				} @else {
					padding-right: rem(35px);
				}
				
				@include media-breakpoint-down(sm) {
					font-size: rem(102px);
				}
			}
			& img {
				max-width: 100%;
				display: block;
			}
		}
		& .error-page-icon i {
			font-size: rem(128px);
			color: $gray-300;
		}
		& h1 {
			font-size: rem(64px);
			font-weight: bold;
			
			@include media-breakpoint-down(sm) {
				font-size: rem(52px);
			}
		}
		& h3 {
			font-size: rem(18px);
			font-weight: 400;
			
			@include media-breakpoint-down(sm) {
				font-size: rem(16px);
			}
		}
		& .link-divider {
			width: rem(4px);
			height: rem(4px);
			display: inline-block;
			background: $gray-300;
			position: relative;
			top: rem(-2px);
			margin: 0 rem(10px);
			border-radius: rem(4px);
		}
	}
}