/*!
 * React Studio v1.0.0 (https://www.seantheme.com/react-studio/)
 * Copyright 2024 SeanTheme
 */

@import 'font.scss';
@import '_functions.scss';
@import '_variables.scss';
@import '_variables-dark.scss';
@import 'node_modules/bootstrap/scss/bootstrap.scss';

@import '_mixins.scss';
@import '_root.scss';
@import '_helper.scss';
@import '_reboot.scss';

@import '_app.scss';
@import '_ui.scss';
@import '_plugins.scss';
@import '_pages.scss';
@import '_widgets.scss';
@import '_layout.scss';
@import '_rtl.scss';