.login {
	min-height: 100vh;
	padding: rem(60px);
	display: flex;
	align-items: center;
	
	@include media-breakpoint-down(md) {
		padding: rem(20px);
	}
	
	& .login-content {
		max-width: rem(360px);
		margin: 0 auto;
		position: relative;
		flex: 1;
	}
}